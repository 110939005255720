import React from "react"
import { KontoInfo } from "../../app/apiSlice"
import Styles from "./KontoInfoView.module.css"

// @ts-expect-error - allow js
import Hjelpetekst from "./Hjelpetekst.jsx"
import { useAppSelector } from "../../app/hooks"
import { useCustomParams } from "../konto/useCustomParams"

type KontoInfoViewParam = {
  konto: KontoInfo
}

export default function KontoInfoView({ konto }: KontoInfoViewParam): React.ReactElement {
  const { kontonummer, navn, metaData } = konto
  const { orgForm, medMoms } = useCustomParams()

  const { kontonumre } = useAppSelector((state) => state.konto)

  return (
    <div className={Styles.kontoInfo}>
      <h3>
        {kontonummer} - {navn}
      </h3>
      <Hjelpetekst hjelpetekst={metaData.hjelpetekst} orgForm={orgForm} medMoms={medMoms} kontonumre={kontonumre} />
    </div>
  )
}
