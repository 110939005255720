import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

export const BrukerRolles = {
  ADMIN: "admin",
  SUPPORT: "support",
  BRUKER: "bruker",
  UKJENT: "ukjent",
} as const

export type BrukerRolle = (typeof BrukerRolles)[keyof typeof BrukerRolles]

export type WhoAmIBrukerInfo = {
  readonly id: number
  readonly name: string
  readonly email: string
  readonly country: string
  readonly phone: string
  readonly regnskapsforer: boolean
}

export type WhoAmI = {
  readonly rolle: BrukerRolle
  readonly brukerInfo?: WhoAmIBrukerInfo
}

export const whoAmIApi = createApi({
  reducerPath: "whoAmI",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://fiken.no",
    credentials: "include",
    headers: {
      "X-Fk-WhoAmI-Source": "fiken-kontohjelp",
    },
  }),
  endpoints: (build) => {
    return {
      fetchWhoAmI: build.query<WhoAmI, void>({
        query: () => "/whoami",
      }),
    }
  },
})

export const { useLazyFetchWhoAmIQuery } = whoAmIApi
