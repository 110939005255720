import { generatePath, matchPath, Params, To, useLocation, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { RoutesName } from "../../app/RoutesName"

export const useGeneratePath = (params: Params, dep: unknown[] = []): To => {
  const { pathname } = useLocation()
  const parameter = useParams()
  const [searchParam] = useSearchParams()

  const [path, setPath] = useState<string>(pathname)
  const routes = Object.values(RoutesName)

  const currentRoute = routes.find((pattern) => {
    return matchPath(pattern, pathname)
  })
  const o = currentRoute ?? RoutesName.ORG_FORM_MED_MOMS

  useEffect(() => {
    setPath(`/${generatePath(o, { ...parameter, ...params })}`)
  }, [pathname, ...dep])

  return {
    pathname: path,
    search: searchParam.toString(),
  }
}
